<template>
  <div class="page">
    <van-skeleton
      :loading="$store.state.ajaxLoading"
      :row="3"
      avatar
      title
    >
      <van-empty
        description="记录为空"
        v-if="!list || !list.length"
      />
      <van-cell-group
        :key="index"
        v-for="(item, index) in list"
      >
        <van-card
          :tag="getIndex(++index)"
          :thumb="item.titlepic"
          :title="item.title"
          @click="showInfo({id: item.id})"
        >
          <div
            class="pv-10"
            slot="desc"
            v-if="item.sku_title"
          >
            <van-tag
              plain
              type="primary"
            >{{item.sku_title}}</van-tag>
          </div>
          <div
            class="gray"
            slot="price"
          >{{item.create_time}}</div>
          <div slot="num">
            花费:{{item.cardfen}}
            <van-icon
              class="warning"
              name="gem-o"
            ></van-icon>
          </div>
        </van-card>
      </van-cell-group>

      <div class="mv-10">
        <van-pagination
          :current-page.sync="page"
          :page-size="size"
          :total="total"
          @current-change="getList"
          layout="prev, pager, next, total"
        ></van-pagination>
      </div>
    </van-skeleton>
  </div>
</template>

<script>
import Vue from "vue";
import { Pagination, Tag, Card } from "vant";
Vue.use(Pagination).use(Tag).use(Card);

export default {
  name: "UserDownBak",
  data() {
    return {
      total: 0,
      page: 1,
      size: 20,
      list: [],
      actionShow: false,
      actions: [
        { name: "打开详情", key: "showInfo" },
        { name: "查看下载信息", key: "showDown" },
      ],
      showDowninfo: false,
      actionInfo: {
        index: 0,
        id: 0,
        classid: 0,
      },
    };
  },
  methods: {
    getList() {
      const params = {
        page: this.page,
        size: this.size,
        loading: true,
      };
      this.$http.get("user/downs", params).then((res) => {
        this.total = res.total;
        this.list = res.data;
      });
    },
    onClick(index) {
      this.actionInfo = {
        index,
        id: this.list[index].id,
        classid: this.list[index].classid,
      };
      this.actionShow = true;
    },
    onSelect(item) {
      if (item.key === "showInfo") {
        let index = this.actionInfo.index;
        let info = this.list[index];
        this.showInfo(info);
      } else if (item.key === "showDown") {
        this.actionShow = false;
        this.showDowninfo = true;
      }
    },
    getIndex: function (num) {
      return num < 10 ? "0" + num : "" + num;
    },
  },
  activated() {
    // 关闭底部Tabbar
    window.scrollTo(0, 0);
    this.showDowninfo = false;
    this.getList();
  },
};
</script>
